import React, { useRef, Suspense, useContext, useState } from "react"

import tw, { css } from "twin.macro"
import { useStaticQuery, graphql, Link } from "gatsby"
import CLink from "../cLink"
import Arrow from "../vector/arrow"

import ClientOnly from "../../helpers/ClientOnly"

import each from "lodash.foreach"
import filter from "lodash.filter"
import includes from "lodash.includes"
import sortby from "lodash.sortby"

import { stringToSlug } from "../../helpers/stringToSlug"
// import { useInView } from "react-intersection-observer"
import { GeneralContext } from "../../contexts/generalContext"
import { translateCustom } from "../../langs/langHelpers"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import Mapa3d from './mapa3d'
// const Mapa3d = React.lazy(() => import("./mapa3d"))

const Mapa = ({lang}) => {
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  // const { ref: mapaThree, inView } = useInView({
  //   /* Optional options */
  //   // threshold: 0,
  //   triggerOnce: true,
  // })

  const canvasContainerRef = useRef()

  const textosProvinciasLiRef = useRef({})
  const textosPaisULContainerRef = useRef({})
  const textosPaisULRef = useRef({})

  const [zonaActual, setZonaActual] = useState("general")
  const [zonaAnterior, setZonaAnterior] = useState(zonaActual)
  const [provinciaHover, setProvinciaHover] = useState(null)
  const cambiaZona = zona => {
    setZonaAnterior(zonaActual)
    setZonaActual(zona)
  }

  const data = useStaticQuery(graphql`
    query mapaQuery {
      allSitePage(filter: { context: { template: { eq: "page_ciudad" } } }) {
        nodes {
          context {
            donde_comprar_slug
            donde_comprar_pais
            donde_comprar_ciudad
            title
            ruta
            lang
            pwid
            pwidlang
            level
          }
        }
      }
    }
  `)
  // const [y, setScrollSpringY] = useSpring(() => ({
  //   // immediate: false,
  //   // config: config.slow,
  //   y: 0,

  // }))
  const scrollToCiudad = ciudadSlug => {
    //onsole.log("scrollToCiudad")
    const liCiudad = textosProvinciasLiRef.current[
      ciudadSlug
    ].getBoundingClientRect()
    const ulCiudad = textosPaisULContainerRef.current[
      zonaActual
    ].getBoundingClientRect()
    const topLiCiudad =
      liCiudad.top -
      ulCiudad.top +
      textosPaisULContainerRef.current[zonaActual].scrollTop
    textosPaisULContainerRef.current[zonaActual].scrollTo(
      0,
      topLiCiudad -
        textosPaisULContainerRef.current[zonaActual].offsetHeight * 0.5
    )
    setProvinciaHover(ciudadSlug)
    // // textosProvinciasLiRef.current[ciudadSlug].scrollIntoView()
    // const liCiudad = textosProvinciasLiRef.current[ciudadSlug].getBoundingClientRect()
    // const topLiCiudad = liCiudad.top
    // setScrollSpringY.stop()
    // setScrollSpringY.start({
    //   y: topLiCiudad,
    //   onChange: props => {
    //     if(textosPaisULContainerRef.current[zonaActual]){
    //       textosPaisULContainerRef.current[zonaActual].scrollTo(0, props.value.y);
    //     }
    //   }
    // })
  }

  let listaCiudades = data.allSitePage.nodes.filter(n => n.context.lang === lang)
  //Translate countries
  each(listaCiudades, ciudad => {
    ciudad.context.donde_comprar_pais_translated = translateCustom(
      "countries",
      ciudad.context.donde_comprar_pais,
      lang
    )
  })
  //onsole.log(listaCiudades)

  const listaPaises = []
  const listaZonas = []
  each(listaCiudades, ciudad => {
    //onsole.log(ciudad)
    if (!includes(listaPaises, ciudad.context.donde_comprar_pais)) {
      listaPaises.push(ciudad.context.donde_comprar_pais)
    }
  })
  each(listaPaises, pais => {
    if (
      filter(listaCiudades, { context: { donde_comprar_pais: pais } })
        .length !== 1
    ) {
      listaZonas.push(pais)
    }
  })
  //Pon en número de paises que hay en cada ciudad (cidades que comparten ciudad)
  each(listaCiudades, ciudad => {
    ciudad.nPaises = filter(listaCiudades, {
      context: { donde_comprar_pais: ciudad.context.donde_comprar_pais },
    }).length
  })
  each(listaCiudades, ciudad => {
    ciudad.slug =
      ciudad.nPaises === 1
        ? stringToSlug(ciudad.context.donde_comprar_pais)
        : ciudad.context.donde_comprar_slug
    // let tituloRecortado = replace(ciudad.context.title,ciudad.context.donde_comprar_pais,'')
    // tituloRecortado = replace(tituloRecortado,' | ','')
    // ciudad.tituloRecortado = tituloRecortado
    ciudad.zona =
      ciudad.nPaises === 1
        ? "general"
        : stringToSlug(ciudad.context.donde_comprar_pais)
  })
  listaCiudades = sortby(listaCiudades, "context.donde_comprar_slug")

  ////const triggerTransition = useTriggerTransition(cTransitionProps) @todo
  const triggerTransition = ({to}) => {
    if(typeof window !== 'undefined'){
      window.location.href = to
    }
  }

  const listaZonasOrderer = [
    filter(listaZonas, zona => zona.substring(0, 3) === "Esp")[0],
    filter(listaZonas, zona => zona.substring(0, 3) === "Fra")[0],
    filter(listaZonas, zona => zona.substring(0, 3) === "Por")[0],
  ]

  const otrosText = t.others
  // const mounted = useRef(false);
  //   useEffect(() => {
  //       mounted.current = true;
  //       onsole.log("Mapa general montada")
  //       return () => {
  //           mounted.current = false;
  //       onsole.log("Mapa general DESmontada")

  //       };
  //   }, []);

  return (
    <div ref={canvasContainerRef} css={[twAccordionContainer, twMapBackground]}>
      <div css={tw`block md:hidden py-4`}>
        {[...listaZonasOrderer, otrosText].map(pais => {
          const paisSlug = pais === otrosText ? "general" : stringToSlug(pais)
          const listaCidadesAreas = filter(listaCiudades, { zona: paisSlug })
          if (listaCidadesAreas.length === 0) return null

          return (
            <Accordion key={pais}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div css={tw`uppercase font-bold text-sm text-marron1`}>
                  {translateCustom("countries", pais, lang)}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul css={tw`block w-full pb-6`}>
                  {listaCidadesAreas.map((ciudad, i) => {
                    const cleanedCityName = ciudad.context.donde_comprar_ciudad.replace(
                      "&#039;",
                      "´"
                    )
                    return (
                      <li
                        key={`${ciudad}-${i}`}
                        css={tw`block border-b border-gris3`}
                      >
                        <CLink
                          to={ciudad.context.ruta}
                          css={tw`block py-2 text-base text-marron1`}
                        >
                          {cleanedCityName}
                        </CLink>
                      </li>
                    )
                  })}
                </ul>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>

      <ClientOnly>
        <div
          css={tw`hidden md:block relative h-mapMobileHeight md:h-auto md:pt-1/2 overflow-y-hidden`}
        >
          {listaPaises.map(pais => {
            const paisSlug = stringToSlug(pais)
            const listaCidadesAreas = filter(listaCiudades, { zona: paisSlug })
            if (listaCidadesAreas.length === 0) return null
            return (
              <div
                ref={element => {
                  textosPaisULContainerRef.current[paisSlug] = element
                }}
                key={paisSlug}
                css={[
                  tw`fixed md:absolute transform transition-transform duration-700 translate-y-0 md:w-1/6 h-100vh md:h-100x100 inset-0 md:inset-y-0 md:left-auto md:right-0 z-10 overflow-y-auto`,
                  zonaActual !== paisSlug && tw`translate-y-100x100`,
                ]}
                style={{ scrollBehavior: "smooth" }}
              >
                <ul
                  css={tw`py-64 text-right pr-3`}
                  // onClick={() => cambiaZona('general')}
                  ref={element => {
                    textosPaisULRef.current[paisSlug] = element
                  }}
                >
                  {listaCidadesAreas.map((ciudad, i) => {
                    const cleanedCityName = ciudad.context.donde_comprar_ciudad.replace(
                      "&#039;",
                      "´"
                    )
                    return (
                      <li
                        key={i}
                        ref={element => {
                          textosProvinciasLiRef.current[ciudad.slug] = element
                        }}
                      >
                        <a
                          onClick={() =>
                            triggerTransition({ to: ciudad.context.ruta })
                          }
                          href={ciudad.context.ruta}
                          css={[
                            tw`inline-block bg-fondo1 py-2 px-2 mb-3 border whitespace-nowrap border-marron1 text-marron1 uppercase text-obsmall font-bold pointer-events-auto`,
                            provinciaHover === ciudad.slug &&
                              tw`bg-marron1 text-fondo1`,
                          ]}
                          onMouseEnter={() => {
                            setProvinciaHover(ciudad.slug)
                          }}
                          onMouseLeave={() => {
                            setProvinciaHover(null)
                          }}
                        >
                          {cleanedCityName}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
          <div
            // ref={mapaThree}
            css={[
              tw`absolute w-full h-mapMobileHeight md:h-100x100 md:inset-0 z-0`,
              provinciaHover !== null && tw`cursor-pointer`,
            ]}
          >
            {/*{inView && (*/}
                <Mapa3d
                  canvasContainerRef={canvasContainerRef}
                  textosProvinciasLiRef={textosProvinciasLiRef}
                  zonaActual={zonaActual}
                  zonaAnterior={zonaAnterior}
                  cambiaZona={cambiaZona}
                  listaCiudades={listaCiudades}
                  listaZonas={listaZonas}
                  scrollToCiudad={scrollToCiudad}
                  provinciaHover={provinciaHover}
                  setProvinciaHover={setProvinciaHover}
                  lang={lang}
                />
            {/*)}*/}
          </div>
          {zonaActual !== "general" && (
            <div
              css={tw`absolute top-0 left-0 cursor-pointer z-10`}
              onClick={() => cambiaZona("general")}
              style={{
                transform: "scale(-100%,100%)",
              }}
            >
              <Arrow color={"#847360"} />
            </div>
          )}
        </div>
      </ClientOnly>
    </div>
  )
}

export default Mapa

const twAccordionContainer = css`
  position: relative;
  ${tw`w-100vw relative inset-x-1/2 -ml-50vw -mr-50vw overflow-x-hidden`}
  &>* {
    ${tw`px-12`}
  }
  & .MuiPaper-root {
    background-color: rgba(255, 255, 255, 0.8);
  }
  & .MuiButtonBase-root {
    background-color: transparent;
  }
`
const twMapBackground = css`
  @media (max-width: 767px) {
    background-image: url(/mobilemap.png);
    background-size: auto 230px;
    background-position: center top;
    background-repeat: no-repeat;
  }
`
